@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@300;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Arimo:wght@400;600;700&display=swap);
* {
  box-sizing: border-box;
}

body {
  background: #000000;
  line-height: 1.5;
  font-family: "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
}

@font-face {
  font-family: FordEmoji;
  src: url(../../static/media/Brand-Live-Emoji.500dc952.otf) format("opentype");
}

